.App {
  color: #000002;
}

.IntroText {
  font-size: large;
  margin-bottom: 40px;
  color: #faf9f6;
}

.Greeting {
  font-family: "Press Start 2P";
}
